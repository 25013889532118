// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography } from '@mui/material';

// hooks
import useResponsive from '../../hooks/useResponsive';

// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';

// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#232323',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
const DivLogoSvg = styled('div')(() => ({
  width: '200px',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp ? (
          <>
            <SectionStyle>
              <DivLogoSvg>
                <Logo sx={{ width: '100%', height: '100%' }} loginPage />
              </DivLogoSvg>
            </SectionStyle>
            <Container maxWidth="sm">
              <ContentStyle>
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" gutterBottom>
                      Welcome to Pathshala
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                  </Box>
                </Stack>
                <LoginForm />
              </ContentStyle>
            </Container>
          </>
        ) : (
          <Container maxWidth="sm">
            <ContentStyle
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // Aligns the content from the top
                minHeight: '100vh',
                p: { xs: 2, md: 5 }, // Reduced padding on mobile
                boxShadow: { xs: 'none', md: '0 8px 16px rgba(0, 0, 0, 0.1)' }, // Only applies box-shadow on desktop
                borderRadius: 2,
                backgroundColor: { xs: 'transparent', md: '#fff' },
                mt: { xs: 1, md: 0 }, // Add margin top on mobile to push content down
              }}
            >
              <DivLogoSvg
                sx={{
                  width: { xs: '300px', md: '400px' }, // Increased size for mobile
                  mb: { xs: -3, md: -5 }, // Gap between logo and login form
                }}
              >
                <Logo sx={{ width: '100%', height: '100%' }} loginPage />
              </DivLogoSvg>

              <LoginForm />

              <Typography sx={{ color: 'black', mt: 3, fontWeight: 'bold' }}>Welcome to Pathshala</Typography>
            </ContentStyle>
          </Container>
        )}
      </RootStyle>
    </Page>
  );
}
