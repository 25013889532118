import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { fDateTime } from '../../../utils/formatTime';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 600,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

const fieldStyle = {
  fontWeight: 'bold',
};

function ActivityDetailLogModal({ open, onClose, log }) {
  const renderDetail = (label, value) => (
    <Grid container spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={4} sx={fieldStyle}>
        <Typography variant="body2" textAlign={'left'}>
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2" textAlign={'left'}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Wrapper open={open} onClose={onClose}>
      <Box>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Close />
        </IconButton>
        {/* <Box display="flex" justifyContent="space-between" alignItems="center"> */}
        <Typography variant="h6" component="h2">
          Log Details
        </Typography>
        {/* </Box> */}
        <Divider sx={{ my: 2 }} />
        {log && (
          <>
            {renderDetail('Type', log.type)}
            {renderDetail('Message', log.message)}
            {renderDetail('Date', fDateTime(log.createdAt))}
            {renderDetail('User', `${log.userId?.username} (${log.userId?.role?.rolename})`)}
            {renderDetail('Creator', `${log.creator?.username} (${log.creator?.role?.rolename})`)}
            {renderDetail(
              'Device Info',
              `${log.deviceDetail?.os?.name} ${log.deviceDetail?.os?.version} - ${log.deviceDetail?.device?.brand} ${log.deviceDetail?.device?.model}`
            )}
            {renderDetail('IP Address', log.ipAddress)}
            {renderDetail('Status', log.status)}
          </>
        )}
      </Box>
    </Wrapper>
  );
}

ActivityDetailLogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  log: PropTypes.object,
};

export default ActivityDetailLogModal;
